<template>
  <div>
    <v-btn @click="save" color="purple lighten-1" dark fab fixed right bottom>
      <v-icon>{{ icons.mdiContentSave }}</v-icon>
    </v-btn>
  </div>
</template>

<script>
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { mdiContentSave } from "@mdi/js";
export default {
  name: "SaveButton",
  emits: ["save"],
  methods: {
    save() {
      this.$emit("save");
    },
  },
  data() {
    return {
      icons: {
        mdiContentSave,
      },
    };
  },
};
</script>
