<template>
  <div>
    <v-form ref="form" v-model="valid">
      <v-text-field
        required
        :rules="[(v) => !!v || 'Title is required']"
        class="mx-10 mt-10"
        v-model="newTodo.title"
        label="Title"
        color="purple"
        outlined
        dark
      />
      <v-text-field
        class="mx-10"
        v-model="newTodo.description"
        label="Description"
        color="purple"
        outlined
        dark
      />
      <v-btn :disabled="!valid" @click="add" class="mx-10" color="purple" dark>
        Add me
      </v-btn>
    </v-form>
  </div>
</template>

<script>
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export default {
  name: "TodoForm",
  emits: ["add", "added"],
  props: ["newTodo"],
  model: {
    prop: "newTodo",
  },
  methods: {
    add() {
      this.$refs.form.validate();
      if (this.valid) {
        this.$emit("add", this.newTodo);
        this.$emit("added", this.newTodo);
        this.$refs.form.resetValidation();
      }
    },
  },
  data() {
    return {
      valid: false,
    };
  },
};
</script>
