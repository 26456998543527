<template>
  <div v-if="todos && todos.length > 0" class="mb-8 pb-8">
    <v-sheet
      v-for="(todo, i) in todos"
      :key="i"
      width="80vw"
      class="mx-auto my-8 px-4 py-2"
      dark
      rounded
      outlined
      :elevation="todo.isDone ? 5 : 0"
      :color="todo.isDone ? '#110b2b' : 'purple darken-3'"
    >
      <v-row no-gutters>
        <v-col cols="1">
          <v-checkbox
            v-model="todo.isDone"
            color="indigo"
            :value="todo.isDone"
            hide-details
          />
        </v-col>
        <v-col cols="9">
          <v-col>
            <v-row
              no-gutters
              :class="`text-h5 font-weight-bold word-break ${
                todo.isDone
                  ? 'text-decoration-line-through'
                  : 'text-decoration-none'
              }`"
            >
              {{ todo.title }}
            </v-row>
            <v-row
              no-gutters
              :class="`text-overline word-break ${
                todo.isDone
                  ? 'text-decoration-line-through'
                  : 'text-decoration-none'
              }`"
            >
              {{ todo.description }}
            </v-row>
          </v-col>
        </v-col>
        <v-col md="2" cols="8" offset="4" class="offset-md-0 actions-container">
          <v-row no-gutters>
            <v-col offset="5" cols="3">
              <v-btn @click="editItem(todo)" color="purple lighten-2" small fab>
                <v-icon dark>{{ icons.mdiPencil }}</v-icon>
              </v-btn>
            </v-col>
            <v-col offset="1" cols="3">
              <v-btn @click="deleteItem(todo)" color="red lighten-2" small fab>
                <v-icon dark>{{ icons.mdiTrashCan }}</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-sheet>
  </div>
  <div v-else>
    <EmptyTodo />
  </div>
</template>

<script>
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { mdiPencil, mdiTrashCan } from "@mdi/js";
import EmptyTodo from "@/components/EmptyTodo.vue";
export default {
  name: "TodoList",
  props: ["todos"],
  emits: ["edit", "delete"],
  components: {
    EmptyTodo,
  },
  methods: {
    editItem(todo) {
      this.$emit("edit", todo);
    },
    deleteItem(todo) {
      this.$emit("delete", todo);
    },
  },
  data() {
    return {
      icons: {
        mdiPencil,
        mdiTrashCan,
      },
    };
  },
};
</script>

<style scoped>
.word-break {
  word-break: break-word;
}
.actions-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
}
</style>
