<template>
  <div>
    <div id="list-id"><span class="mr-2">ID</span>{{ $route.params.id }}</div>

    <div class="d-flex justify-center white--text display-1 mt-4 mx-8">
      {{ title }}
    </div>
    <TodoForm @add="handleAdd" @added="handleClearForm" v-model="newTodo" />
    <TodoList :todos="todos" @edit="handleEdit" @delete="handleDelete" />
    <SaveButton @save="handleSave" />
  </div>
</template>

<script>
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import SaveButton from "@/components/SaveButton.vue";
import TodoForm from "@/components/TodoForm.vue";
import TodoList from "@/components/TodoList.vue";

const emptyTodoObject = {
  title: undefined,
  description: undefined,
  isDone: false,
};

export default {
  name: "Todo",
  components: {
    SaveButton,
    TodoList,
    TodoForm,
  },
  mounted() {
    this.handleLoad();
  },
  methods: {
    handleLoad() {
      const zudoLists = localStorage.getItem(`zudo.data.lists`);
      const zudoData = localStorage.getItem(
        `zudo.data.${this.$route.params.id}`
      );
      this.todos = JSON.parse(zudoData || "[]");
      this.title = JSON.parse(zudoLists || "[]").filter(
        (list) => list.id.toString() === this.$route.params.id
      )[0]?.title;
    },
    handleSave() {
      localStorage.setItem(
        `zudo.data.${this.$route.params.id}`,
        JSON.stringify(this.todos)
      );
      this.$toast.success("Your Zudos have been saved successfully", {
        position: "top-right",
        timeout: 3000,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: "button",
        icon: true,
      });
    },
    handleEdit(todo) {
      this.newTodo = {
        title: todo.title,
        description: todo.description,
        isDone: todo.isDone,
      };
      const filteredTodos = this.todos.filter((td) => td != todo);
      this.todos = [...filteredTodos];
    },
    handleDelete(todo) {
      this.todos = this.todos.filter((td) => td != todo);
    },
    handleAdd({ title, description, isDone }) {
      this.todos.push({
        title,
        description,
        isDone,
        id: Date.now(),
      });
    },
    handleClearForm() {
      this.newTodo = { ...emptyTodoObject };
    },
  },
  data() {
    return {
      todos: [],
      title: undefined,
      newTodo: { ...emptyTodoObject },
    };
  },
};
</script>

<style scoped>
#list-id {
  color: white;
  font-weight: lighter;
  font-size: smaller;
  width: fit-content;
  margin: 1rem;
}
</style>
